import Axios from 'axios';

const LOGOUT_RESOURCE = process.env.VUE_APP_API_ENDPOINT + '/api/logout';

export default {
  ssoLogout(token) {
    console.log(token);
    return Axios.get(`${LOGOUT_RESOURCE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

