<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <!-- <th>Associate ID</th> -->
          <!-- <th class="select" @click="selectAll()">Select</th> -->
          <th>Associate Name</th>
          <th>Equipment</th>
          <th>Trainer</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="certification in certifications">
          <OverviewTableRow :key="certification.associate_id">
            <!-- <td>
              <input
                v-model="selectedItems"
                @change="$emit('selectBatch', selectedItems)"
                :value="[
                  certification.id,
                  `${certification.first_name} ${certification.last_name}`,
                ]"
                type="checkbox"
              />
            </td> -->
            <td>
              {{ certification.last_name | capitalize }},
              {{ certification.first_name | capitalize }}
            </td>
            <td>{{ certification.certs | formatCerts }}</td>
            <td>
              {{ isTrainer(certification.certs) || "No" }}
            </td>
            <td>
              <router-link
                :to="{
                  name: 'Licenses',
                  params: { certId: certification.id, store: location },
                }"
                >View</router-link
              >
            </td>
          </OverviewTableRow>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import OverviewTableRow from "@/components/OverviewTableRow.vue";
export default {
  components: {
    OverviewTableRow,
  },
  props: ["certifications", "location"],
  computed: {},
  data() {
    return {
      selectedItems: [],
    };
  },
  methods: {
    check(id) {
      this.selected.push(id);

      return;
    },
    selectAll() {
      if (this.selectedItems.length) this.selectedItems = [];
      else {
        this.certifications.forEach((item) => {
          this.selectedItems.push([
            item.id,
            `${item.first_name} ${item.last_name}`,
          ]);
          // this.selected.push(item.id);
          // this.selectedItems.push(item.id);
        });
        this.$emit("selectBatch", this.selectedItems);
      }
      return;
    },
    showRow(certs) {
      return (
        certs.length > 1 &&
        certs.includes(
          "Store/MET Principles of Lift Truck Safety Certification"
        )
      );
    },
    isTrainer(certifications) {
      return this.$options.filters.trainer(certifications);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  display: grid;
  margin-bottom: 50px;
  max-height: 525px;
  overflow-y: scroll;
}

input[type="checkbox"] {
  accent-color: #fa7721;
  color-scheme: light;
  height: 17px;
  width: 17px;
  color: white;
  cursor: pointer;
}
.select {
  color: #f76b1c;
  cursor: pointer;
}
table {
  text-align: left;
  border-spacing: 0;
  margin-right: 40px;
  font-size: 14px;

  thead {
    text-transform: uppercase;

    th {
      border-bottom: solid 3px #dbdada;
      padding: 10px 0;
    }
  }

  td {
    border-bottom: solid 1px #dbdada;
    padding: 15px 0;
  }

  a {
    color: #f76b1c;
    text-decoration: none;
  }
}
</style>
