<template>
  <div class="associate__name">
    <div class="associate__name--hr">
      <strong>Associate Name:</strong>
      {{ this.first_name | capitalize }}
      {{ this.last_name | capitalize }}
    </div>
    <div class="associate__name--preferred">
      <strong>Printed Name: </strong>
      <i>(optional)</i>
      <input type="text" v-model="name" />
      <Button text="Update" @click.native="updateName()" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  components: {
    Button,
  },
  props: {
    first_name: String,
    last_name: String,
  },
  data() {
    return {
      name: "",
    };
  },
  methods: {
    updateName() {
      this.$emit("updateName", this.name);
    },
  },
};
</script>

<style lang="scss">
.associate__name {
  align-items: baseline;
  display: grid;
  grid-area: name;
  grid-template-columns: 434px 1fr;
  background-color: #f0efef;
  padding: 15px 40px;
  font-size: 14px;

  .associate__name--hr {
    justify-self: left;
  }

  .associate__name--preferred {
    justify-self: left;

    input {
      padding: 5px 10px;
      border-radius: 5px;
      border: solid 1px #c3c3c3;
      margin: 0 10px;
      font-family: "Lato", Arial, Helvetica, sans-serif;
    }

    button {
      display: inline-block;
      height: 30px;
      padding: 0 20px;
    }
  }
}
</style>
