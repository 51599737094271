import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

import './filters';

Vue.use(VueAxios, axios, VueCookies);

Vue.config.productionTip = false;

// VueAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
