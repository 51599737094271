<template>
  <button :style="'width:' + this.width + 'px'">{{ text }}</button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    width: String
  }
}
</script>

<style lang="scss" scoped>
  button {
    background-image: linear-gradient(#FA9417, #F76B1C);
    border: none;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: grid;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: bold;
    height: 44px;
    letter-spacing: 1px;
    outline: none;
    text-transform: uppercase;
    align-content: center;
    padding: 0 40px;
  }

  .support, .back {
    background: none;
    color: #313133;
    border: solid 2px #ACACAC;
  }

  .support {
    margin-right: 20px;
  }

  .back {
    background: url('/assets/back-arrow.svg') 15px center no-repeat;
  }

  .download-report {
    background-color: linear-gradient(#FA9417, #F76B1C);
    padding: 0 55px 0 30px;
    text-align: left;
    background: url('../assets/document-icon.svg') center right 25px no-repeat, linear-gradient(#FA9417, #F76B1C);
  }

  .download-license {
    background-color: linear-gradient(#FA9417, #F76B1C);
    padding: 0 55px 0 30px;
    text-align: left;
    background: url('../assets/download-icon.svg') center right 25px no-repeat, linear-gradient(#FA9417, #F76B1C);
  }

</style>