<template>
  <div class="overlay">
    <div class="modal">
      <div class="title">Batch Print</div>
      <div class="text">Select an orientation:</div>
      <div class="orientation">
        <div
          v-if="view === 'horizontal'"
          class="selected"
          @click="view = 'horizontal'"
        >
          <img class="icon" src="../assets/lrg-white-horiz.png" alt="" />
        </div>
        <div
          v-if="view === 'vertical'"
          class="unselected"
          @click="view = 'horizontal'"
        >
          <img class="icon" src="../assets/lrg-black-horiz.png" alt="" />
        </div>
        <div
          v-if="view === 'vertical'"
          class="selected"
          @click="view = 'vertical'"
        >
          <img class="icon" src="../assets/lrg-white-vert.png" alt="" />
        </div>
        <div
          v-if="view === 'horizontal'"
          class="unselected"
          @click="view = 'vertical'"
        >
          <img class="icon" src="../assets/lrg-black-vert.png" alt="" />
        </div>
      </div>
      <div @click="$emit('selectView', view)" class="button">Continue</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["selectedItems", "type"],
  computed: {},
  data() {
    return {
      view: "horizontal",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.text {
  color: #313133;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 25px;
}
.orientation {
  display: flex;
  margin-bottom: 50px;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  box-sizing: border-box;
  width: 379px;
  height: 378px;
  text-align: left;
  padding: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #fff;
}

.selected {
  width: 66px;
  height: 66px;
  cursor: pointer;
}
.title {
  color: #f76b1c;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Lato;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 45px;
}
.unselected {
  cursor: pointer;

  width: 66px;
  height: 66px;
}
.button {
  cursor: pointer;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  display: inline-flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: linear-gradient(180deg, #fa9417 0%, #f76b1c 100%);
  width: 75px;
}
</style>
