import Vue from "vue";

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  value = value.toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("formatCerts", function(values) {
  let certs = values
    .map((value) => {
      switch (value) {
        case "Store/MET Principles of Lift Truck Safety Certification":
          return "";
        case "Store/MET Lift Truck Trainer Curriculum":
          return "";
        case "Electric Ladder Trainer":
          return "";
        case "Store/MET Ballypal Powered Pallet Jack Trainer Curriculum":
          return "";
        case "Store/MET Portable Loading Dock Trainer Curriculum":
          return "";
        case "Store/MET Lift Truck Training Reach Truck":
          return " RT";
        case "Store/MET Lift Truck Training Electric Pallet Jack Certification":
          return " EPJ";
        case "Store/MET Lift Truck Training Order Picker":
          return " OP";
        case "Store/MET Lift Truck Training Pacer Unit":
          return " Pacer";
        case "Store/MET Lift Truck Training Sit Down Fork Lift":
          return " FL";
        case "Electric Ladder Training":
          return " EL";
        case "Store/MET BallyPal Powered Pallet Jack Certification":
          return " BP";
        case "Store/MET Portable Loading Dock Certification":
          return " PLD";
        default:
          return value;
      }
    })
    .toString()
    .replace(/,\s*$/, "")
    .replace(/,,\s*/, ",")
    .replace(/,,\s*/, ", ")

  if (certs.charAt(0) == ",") {
    certs = certs.substring(1);
  }

  return certs;
});

Vue.filter("formatSingleCerts", function(value) {
  if (!value) return "";

  switch (value) {
    case "Store/MET Principles of Lift Truck Safety Certification":
      return "Principles of Lift Truck Safety";
    case "Store/MET Lift Truck Trainer Curriculum":
      return;
    case "Electric Ladder Trainer":
      return ;
    case "Store/MET Ballypal Powered Pallet Jack Trainer Curriculum":
      return;
    case "Store/MET Portable Loading Dock Trainer Curriculum":
      return;
    case "Store/MET Lift Truck Training Reach Truck":
      return "Reach Truck";
    case "Store/MET Lift Truck Training Electric Pallet Jack Certification":
      return "Electric Pallet Jack";
    case "Store/MET Lift Truck Training Order Picker":
      return "Order Picker";
    case "Store/MET Lift Truck Training Pacer Unit":
      return "Pacer";
    case "Store/MET Lift Truck Training Sit Down Fork Lift":
      return "Forklift";
    case "Electric Ladder Training":
      return "Electric Ladder";
    case "Store/MET BallyPal Powered Pallet Jack Certification":
      return "BallyPal";
    case "Store/MET Portable Loading Dock Certification":
      return "Portable Loading Dock";
    default:
      return value;
  }
});

Vue.filter("formatDepartments", function(values) {
  let departments = values.map((dept) => {
    dept.old_dept_name = dept.dept_name;
    dept.dept_name = dept.dept_name.replace(/^0+/, "");
    return dept;
  });

  departments = departments.filter((dept) => !dept.dept_name.startsWith("SUP"));
  departments = departments.sort(
    (a, b) =>
      parseInt(a.dept_name.split(" ")[0]) - parseInt(b.dept_name.split(" ")[0])
  );

  return departments;
});

Vue.filter("formatSingleDepartment", function(value) {
  return value.dept_name.replace(/^0+/, "");
});

Vue.filter("trainer", function(values) {
  if (!values.length) return "";

  let trainers = [];

  if (
    values.includes("Store/MET Lift Truck Trainer Curriculum") &&
    values.includes("Electric Ladder Trainer")
  ) {
    trainers.push("ELT/LTT");
  }

  if (
    values.includes("Electric Ladder Trainer") &&
    !values.includes("Store/MET Lift Truck Trainer Curriculum")
  ) {
    trainers.push("ELT");
  }

  if (
    values.includes("Store/MET Lift Truck Trainer Curriculum") &&
    !values.includes("Electric Ladder Trainer")
  ) {
    trainers.push("LTT");
  }

  if (
    values.includes("Store/MET Ballypal Powered Pallet Jack Trainer Curriculum")
  ) {
    trainers.push("BPT");
  }

  if (values.includes("Store/MET Portable Loading Dock Trainer Curriculum")) {
    trainers.push("PLDT");
  }
  return trainers.join("/");
});

Vue.filter("filterDate", (date) => {
  const arr = date?.split('-')
if(arr?.length){
  return `${arr[1]}/${arr[2]}/${arr[0]}`;
} else return date
});

Vue.filter("filterBPDate", (date) => {
  let d = date.split("-");

  return `${d[1]}/${d[2]}/${d[0]}`;
});
