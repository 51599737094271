import Axios from 'axios'

const EMAIL_RESOURCE = process.env.VUE_APP_API_ENDPOINT + `/api/send-email`

export default {
  sendEmail(email, message) {
    return Axios.post(`${EMAIL_RESOURCE}`, {
      email: email,
      message: message
    })
  }
};