import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Overview from '../views/Overview.vue';
import LicenseDetails from '../views/LicenseDetails.vue';
import StoreSelect from '../views/StoreSelect.vue';
import NotAuthorized from '../views/NotAuthorized.vue';
// import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter, VueCookies);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/overview/:store',
    name: 'Overview',
    component: Overview,
    props: true,
  },
  {
    path: '/license-details/:certId',
    name: 'Licenses',
    component: LicenseDetails,
    props: true,
  },
  {
    path: '/store-select',
    name: 'StoreSelect',
    component: StoreSelect,
    props: true,
  },
  {
    path: '/unauthorized',
    component: NotAuthorized,
  },
  // {
  //   path: '*',
  //   redirect: '/404'
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

//const getCookie = (name) => {
//const value = `; ${document.cookie}`;
//const parts = value.split(`; ${name}=`);
//if (parts.length === 2) return parts.pop().split(';').shift();
//};

//router.beforeEach((to, from, next) => {
//if (to.path === '/unauthorized' || to.path === '/') {
//next();
//return;
//}
//if (typeof getCookie('authApproved') == 'undefined' || getCookie('authApproved') === 'false') {
//localStorage.clear();
//next('/unauthorized');
//return;
//}
//next();
//});

export default router;
