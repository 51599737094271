<template>
  <div class="overview">
    <div class="overview__header-container">
      <Header :email="this.email" />
    </div>
    <div class="cert__header">
      <LogoCertificationDetails />
      {{ this.certification.email }}
      <Button class="back" text="Back" @click.native="goBack" />
      <Button
        class="download-license"
        text="Download License"
        @click.native="downloadLicense"
        :disabled="disabled"
      />
    </div>
    <AssociateName
      :first_name="certification.first_name"
      :last_name="certification.last_name"
      v-on:updateName="displayName"
    />

    <div class="overview__license">
      <CertificationDetails
        :certs="this.getCerts()"
        :ladder="this.hasLadder()"
        :ladder-trainer="isLadderTrainer()"
        :lift-trainer="isLiftTrainer()"
        :ballypal-trainer="isBallyPalTrainer()"
        :pld-trainer="isPldTrainer()"
        :name="fullName"
        :principles="getPrinciples()"
        :store="this.store"
        @changeView="
          (view) => {
            currentView = view;
          }
        "
      />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import LogoCertificationDetails from '@/components/LogoCertificationDetails.vue';
import Button from '@/components/Button.vue';
import AssociateName from '@/components/AssociateName.vue';
import CertificationDetails from '@/components/CertificationDetails.vue';
import CertificationService from '@/services/certification.service';
import LicenseService from '@/services/license.service';

export default {
  name: 'Certification',
  props: ['certId'],
  components: {
    Header,
    Button,
    LogoCertificationDetails,
    AssociateName,
    CertificationDetails,
  },
  computed: {
    fullName() {
      if (this.name) {
        return this.name;
      }

      return `${this.certification.first_name} ${this.certification.last_name.charAt(0)}.`;
    },
  },
  data() {
    return {
      certification: {
        first_name: '',
        last_name: '',
        certs: [],
        email: '',
        location: '',
        store: '',
      },
      currentView: 'horizontal',
      equip: [
        'Store/MET Lift Truck Training Reach Truck',
        'Store/MET Lift Truck Training Electric Pallet Jack Certification',
        'Store/MET Lift Truck Training Order Picker',
        'Store/MET Lift Truck Training Pacer Unit',
        'Store/MET Lift Truck Training Sit Down Fork Lift',
        'Store/MET Portable Loading Dock Certification',
        'Store/MET Portable Loading Dock Certification',
        'Store/MET BallyPal Powered Pallet Jack Certification',
        'Electric Ladder Training',
      ],
      name: '',
      email: '',
      store: '',
      disabled: false,
    };
  },
  methods: {
    downloadLicense() {
      let certId = this.$route.params.certId;
      let name;

      this.disabled = true;

      if (this.name) {
        name = this.name;
      } else {
        name = `${this.certification.first_name} ${this.certification.last_name.charAt(0)}.`;
      }

      LicenseService.getLicenseByCertID(certId, name, this.currentView)
        .then((res) => {
          const data = new Blob([res.data]);
          const defaultFilename = `${name}.pdf`;
          if (typeof window.navigator.msSaveBlob === 'function') {
            // If it is IE that support download blob directly.
            window.navigator.msSaveBlob(data, defaultFilename);
          } else {
            var blob = data;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = defaultFilename;

            document.body.appendChild(link);

            link.click(); // create an <a> element and simulate the click operation.
          }

          this.disabled = false;
        })
        .catch((err) => console.log(err));
    },
    displayName(val) {
      this.name = val;
    },
    goBack() {
      return this.$router.go(-1);
    },
    hasLadder() {
      let containsCerts = [];

      this.certification.certs.map((value) => {
        if (value.cert == 'Electric Ladder Training') {
          containsCerts.push(value.cert);
        }
      });

      return containsCerts.length > 0;
    },
    getCerts() {
      let containsCerts = [];
      // let filteredCerts = []
      // const gFilters = this.$options.filters

      this.certification.certs.map((value) => {
        if (this.equip.includes(value.cert)) {
          containsCerts.push(value);
        }
      });
      return containsCerts;
    },
    isLiftTrainer() {
      return (
        this.certification.certs.filter(
          (cert) => cert.cert == 'Store/MET Lift Truck Trainer Curriculum',
        ).length > 0
      );
    },
    isLadderTrainer() {
      return (
        this.certification.certs.filter((cert) => cert.cert == 'Electric Ladder Trainer').length > 0
      );
    },
    isBallyPalTrainer() {
      return (
        this.certification.certs.filter(
          (cert) => cert.cert == 'Store/MET Ballypal Powered Pallet Jack Trainer Curriculum',
        ).length > 0
      );
    },
    isPldTrainer() {
      return (
        this.certification.certs.filter(
          (cert) => cert.cert == 'Store/MET Portable Loading Dock Trainer Curriculum',
        ).length > 0
      );
    },
    getPrinciples() {
      return this.certification.certs.filter(
        (cert) => cert.cert == 'Store/MET Principles of Lift Truck Safety Certification',
      );
    },
  },
  mounted() {
    let cert = this.$route.params.certId;

    CertificationService.getByCertId(cert)
      .then((res) => {
        this.certification = res.data;
        this.store = res.data.location_name.match(/\d+/)[0];
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style lang="scss" scoped>
.overview__license {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
}
.cert__header {
  grid-area: content-header;
  display: grid;
  padding: 31px 40px;
  grid-template-columns: 50% 25% 25%;
  align-items: center;

  button {
    display: grid;
    grid-column-start: 3;
    grid-row: 1;
    justify-self: right;
  }

  .back {
    grid-column-start: 2;
  }
}
</style>
