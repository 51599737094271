<template>
  <div class="login store-select">
    <Logo width="225px" height="179px" />
    <p>Please input 4 digit store number</p>
    <input
      type="text"
      id="store-no"
      placeholder="Store Number"
      v-model="storeNumber"
      maxlength="4"
      @keypress.enter="sendLocation"
    />
    <Button text="Continue" width="180" @click.native="sendLocation" />
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import Button from "@/components/Button.vue";

export default {
  name: "Login",
  components: {
    Button,
    Logo,
  },
  data() {
    return {
      valid: false,
      storeNumber: null,
    };
  },
  watch: {
    storeNumber(value) {
      let store = document.querySelector("#store-no");

      if (value.length != 4) {
        store.classList.add("error");
      } else {
        store.classList.remove("error");
        this.valid = true;
      }
    },
  },
  methods: {
    sendLocation() {
      if (this.valid) {
        this.$router.push({
          name: "Overview",
          params: { store: this.storeNumber },
        });
      }
    },
  },
  beforeMount() {
    const token = this.$route.query.token;
    const email = this.$route.query.email;
    const title = this.$route.query.title;
    const location = this.$route.query.location;

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("email", email);
      localStorage.setItem("title", title);
      localStorage.setItem("location", location);
    }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style lang="scss">
.login {
  align-items: center;
  background: rgba(46, 17, 1, 0.7);
  border-radius: 50px;
  box-sizing: border-box;
  display: grid;
  height: 506px;
  justify-items: center;
  padding: 53px 52px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 355px;

  p {
    color: #fff;
    font-size: 16px;
    display: flex;
    line-height: 19px;
    margin: 42px 0;
    text-align: left;
    width: 257px;
  }

  input[type="text"] {
    border: none;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    height: 39px;
    outline: none;
    padding: 8px 6px;
    width: 180px;

    &.error {
      background: #f8d7da;
    }
  }

  &.store-select {
    p {
      margin: 0;
    }
  }
}
</style>
