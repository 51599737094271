<template>
  <form class="filters" @submit="serializeForm">
    <label for="electric-ladder">
      <input
        @change="serializeForm"
        type="checkbox"
        id="electric-ladder"
        value="EL"
      />
      <span></span>
      Electric Ladder (EL)
    </label>
    <label for="forklift">
      <input @change="serializeForm" type="checkbox" id="forklift" value="FL" />
      <span></span>
      Forklift (FL)
    </label>
    <label for="reach">
      <input @change="serializeForm" type="checkbox" id="reach" value="RT" />
      <span></span>
      Reach Truck (RT)
    </label>
    <label for="op">
      <input @change="serializeForm" type="checkbox" id="op" value="OP" />
      <span></span>
      Order Picker (OP)
    </label>
    <label for="pacer">
      <input @change="serializeForm" type="checkbox" id="pacer" value="Pacer" />
      <span></span>
      Pacer
    </label>
    <label for="epj">
      <input @change="serializeForm" type="checkbox" id="epj" value="EPJ" />
      <span></span>
      Electric Pallet Jack (EPJ)
    </label>
    <label for="bp">
      <input @change="serializeForm" type="checkbox" id="bp" value="BP" />
      <span></span>
      BallyPal (BP)
    </label>
    <label for="pld">
      <input @change="serializeForm" type="checkbox" id="pld" value="PLD" />
      <span></span>
      Portable Loading Dock (PLD)
    </label>

    <h4>Trainer</h4>
    <label for="electric-ladder-trainer">
      <input
        @change="serializeForm"
        type="checkbox"
        id="electric-ladder-trainer"
        value="ELT"
      />
      <span></span>
      Electric Ladder (ELT)
    </label>
    <label for="lift-truck-trainer">
      <input
        @change="serializeForm"
        type="checkbox"
        id="lift-truck-trainer"
        value="LTT"
      />
      <span></span>
      Lift Truck (LTT)
    </label>
    <label for="bally-pal-trainer">
      <input
        @change="serializeForm"
        type="checkbox"
        id="bally-pal-trainer"
        value="BPT"
      />
      <span></span>
      BallyPal (BPT)
    </label>
    <label for="portable-loading-dock-trainer">
      <input
        @change="serializeForm"
        type="checkbox"
        id="portable-loading-dock-trainer"
        value="PLDT"
      />
      <span></span>
      Portable Loading Dock (PLDT)
    </label>
    <!-- <Button text="Apply" /> -->
  </form>
</template>

<script>
// import Button from "@/components/Button";

export default {
  components: {
    // Button,
  },
  methods: {
    serializeForm(e) {
      e.preventDefault();

      let filters = [];
      let inputs = document.querySelectorAll(
        '.filters input[type="checkbox"]:checked'
      );

      for (let i = 0; i < inputs.length; i++) {
        filters.push(inputs[i].value);
      }

      this.$emit("filter-certs", filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  font-size: 14px;

  button {
    padding: 0 40px;
    margin: 20px 0 0;
  }
}

label {
  display: block;
  text-align: left;
  margin-bottom: 10px;

  input[type="checkbox"] {
    display: none;

    & + span {
      background: url("../assets/unchecked.svg") center center no-repeat;
    }

    &:checked + span {
      background: url("../assets/checked.svg") center center no-repeat;
    }
  }

  span {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 5px;
    vertical-align: middle;
  }
}
</style>
