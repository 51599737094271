<template>
  <div class="container">
    <h1>Not Authorized</h1>
    <p>Please see your manager to print a license</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>
