<template>
  <div class="header">
    <img src="@/assets/home-icon.svg" />
    <span>Lift Equipment Reporting &amp; License Tool</span>
    <div class="header-session">
      <div class="user-email">
        <img src="@/assets/person-icon.svg" width="19px" height="19px" /> Hello, {{ email }}
      </div>
      <div class="logout" @click="ssoLogout">
        Sign Out
      </div>
    </div>
  </div>
</template>

<script>
import SessionService from '@/services/session.service';

export default {
  data() {
    return {
      email: localStorage.getItem('email'),
    };
  },
  methods: {
    ssoLogout() {
      let t = localStorage.getItem('token');
      if (process.env.NODE_ENV === 'development') {
        localStorage.clear();
        this.$router.push({ path: '/' });
      }
      SessionService.ssoLogout(t).then((res) => {
        localStorage.clear();
        window.location.href = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.header {
  align-items: center;
  color: #fff;
  height: 50px;
  background: #1a1a1a;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  font-size: 21px;
  font-weight: bold;
  grid-area: header;
  grid-column-start: 1;

  img {
    margin: 0 11px 0 51px;
    display: grid;
    grid-column: 1;
    grid-row: 1;
  }

  span {
    display: grid;
    grid-column: 1;
    grid-row: 1;
  }

  .header-session {
    grid-column: 2;
    grid-row: 1;
    text-align: right;

    .user-email,
    .logout {
      display: inline-block;
      font-size: 14px;
    }

    .logout {
      background: rgba(221, 221, 221, 0.17);
      height: 50px;
      vertical-align: center;
      line-height: 50px;
      width: 112px;
      text-align: center;
      margin-left: 30px;
      cursor: pointer;
    }

    .logout:hover {
      background-color: #f76b1c;
    }

    img {
      display: inline-block;
      margin: 0 12px 0 0;
      vertical-align: middle;
    }
  }
}
</style>

