<template>
  <div class="certification-details">
    <div class="certification-details__equipment">
      <div class="headers">
        <h4 class="equipment-name">Equipment</h4>
        <h4 class="equipment-expiration">Expiration</h4>
        <h4 class="equipment-trainer">Trainer</h4>
      </div>
      <div
        class="equipment-container"
        v-for="(cert, index) in this.filterCerts()"
        :key="randomNumber(index)"
      >
        <p class="equipment">{{ cert.cert | formatSingleCerts }}</p>
        <p class="expiration">{{ cert.exp | filterDate }}</p>
        <p class="trainer">{{ liftTrainer ? 'Y' : 'N' }}</p>
      </div>

      <div
        class="equipment-container principles"
        v-for="(cert, index) in this.principles"
        :key="randomNumber(index)"
      >
        <p class="equipment">
          {{ cert.cert | formatSingleCerts }}
        </p>
        <p class="expiration">{{ cert.exp | filterDate }}</p>
        <p class="trainer"></p>
      </div>

      <div v-if="this.ladder.length || this.ladderTrainer" class="electric-ladder">
        <p class="equipment">Electric Ladder</p>
        <p class="expiration">NO EXP</p>
        <p class="trainer">{{ ladderTrainer ? 'Y' : 'N' }}</p>
      </div>
      <div v-if="this.ballyPal.length || this.ballypalTrainer" class="electric-ladder">
        <p class="equipment">BallyPal</p>
        <p class="expiration">
          <span v-if="this.ballyPal[0]">
            {{ this.ballyPal[0].exp | filterDate }}
          </span>
        </p>

        <p class="trainer">{{ this.ballypalTrainer ? 'Y' : 'N' }}</p>
      </div>

      <div v-if="this.pld.length || pldTrainer" class="electric-ladder">
        <p class="equipment">Portable Loading Dock</p>
        <p class="expiration">
          <span v-if="this.pld[0]">
            {{ this.pld[0].exp | filterDate }}
          </span>
        </p>
        <p class="trainer">{{ pldTrainer ? 'Y' : 'N' }}</p>
      </div>
    </div>
    <div class="right">
      <div class="right-top">
        <h4 class="license-text">License Preview</h4>
        <span class="orientation-text">
          Orientation:
          <div
            v-if="view === 'horizontal'"
            class="selected"
            @click="(view = 'horizontal'), $emit('changeView', view)"
          >
            <img src="../assets/white-horiz.png" alt="" />
          </div>
          <div
            v-if="view === 'vertical'"
            class="unselected"
            @click="(view = 'horizontal'), $emit('changeView', view)"
          >
            <img src="../assets/black-horiz.png" alt="" />
          </div>
          <div
            v-if="view === 'vertical'"
            class="selected"
            @click="(view = 'vertical'), $emit('changeView', view)"
          >
            <img src="../assets/white-vert.png" alt="" />
          </div>
          <div
            v-if="view === 'horizontal'"
            class="unselected"
            @click="(view = 'vertical'), $emit('changeView', view)"
          >
            <img src="../assets/black-vert.png" alt="" />
          </div>
        </span>
      </div>
      <div class="preview">
        <div v-if="view === 'horizontal'" class="horizontal">
          <div class="horizontal-top">
            <img class="logo" src="../assets/new.png" alt="" />
            <div class="horizontal-top-right">
              <div class="horizontal-top-right-top">
                <div class="horizontal-top-right-top-left">
                  <div class="lift-equipment-license">LIFT EQUIPMENT LICENSE</div>
                  <div class="store">STORE {{ store }}</div>
                </div>
                <div class="horizontal-top-right-top-right">
                  <div v-if="this.ladderTrainer || this.liftTrainer" class="badge__info-trainer">
                    <div class="star"><img src="~@/assets/star.png" /></div>
                    <strong>TRAINER</strong>
                  </div>
                </div>
              </div>
              <div class="horizontal-top-right-bottom name">{{ name }}</div>
            </div>
          </div>
          <div class="horizontal-bottom">
            <div class="horizontal-bottom-left">
              <table class="table" :class="liftTrainer ? 'trainer-table' : ''">
                <tr class="principle-row tr">
                  <td class="title-column1 principle td">
                    <i v-if="this.principles[0]">{{
                      this.principles[0].cert | formatSingleCerts
                    }}</i>
                  </td>
                  <td class="principle td principle-date">
                    <i v-if="this.principles[0]">{{ this.principles[0].exp | filterDate }}</i>
                  </td>
                </tr>
              </table>

              <table class="table">
                <tr class="title-row tr">
                  <td class="title-column1 td">LIFT TRUCKS</td>
                  <td class="td date-title">EXPIRATION</td>
                </tr>
              </table>

              <table class="table">
                <!-- {{
                  certs.find((item) => item.cert === "Electric Ladder Training")
                    .cert
                }} -->
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && this.fl.length ? 'star-column td orange' : 'star-column td'
                    "
                  >
                    <img v-if="liftTrainer && this.fl.length" src="~@/assets/star.png" alt="" />
                  </td>
                  <td class="column1 td">
                    {{ certs.find((item) => item.cert.includes('Fork Lift')) ? 'Forklift' : '' }}
                    <!-- <img v-if="liftTrainer" src="~@/assets/star.png" /> -->
                  </td>
                  <td class="td date">
                    {{ findCertDate('Fork Lift') }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Electric Pallet Jack')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Electric Pallet Jack')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Electric Pallet Jack'))
                        ? 'Electric Pallet Jack'
                        : ''
                    }}
                  </td>
                  <td class="td date">
                    {{ findCertDate('Electric Pallet Jack') }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Order Picker')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Order Picker')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Order Picker')) ? 'Order Picker' : ''
                    }}
                    <!-- <img v-if="liftTrainer" src="~@/assets/star.png" /> -->
                  </td>
                  <td class="td date">
                    {{ findCertDate('Order Picker') }}
                  </td>
                </tr>

                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Pacer')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Pacer')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{ certs.find((item) => item.cert.includes('Pacer')) ? 'Pacer' : '' }}
                    <!-- <img v-if="liftTrainer" src="~@/assets/star.png" /> -->
                  </td>
                  <td class="td date">
                    {{ findCertDate('Pacer') }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Reach Truck')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Reach Truck')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Reach Truck')) ? 'Reach Truck' : ''
                    }}
                    <!-- <img v-if="liftTrainer" src="~@/assets/star.png" /> -->
                  </td>
                  <td class="td date">
                    {{ findCertDate('Reach Truck') }}
                  </td>
                </tr>
              </table>

              <table class="table">
                <tr class="title-row tr">
                  <td class="title-column1 td">ADDITIONAL LIFT EQUIPMENT</td>
                  <td class="td date-title">EXPIRATION</td>
                </tr>
              </table>

              <table class="table extra">
                <tr class="tr">
                  <td :class="this.ladderTrainer ? 'star-column td orange' : 'star-column td'">
                    <img v-if="this.ladderTrainer" src="~@/assets/star.png" />
                  </td>
                  <td class="column1 td">
                    {{ this.ladder.length || this.ladderTrainer ? 'Electric Ladder ' : '' }}
                  </td>
                  <td class="td date">
                    {{ this.ladderTrainer || this.ladder.length ? 'NO EXP.' : '' }}
                  </td>
                </tr>

                <tr class="tr">
                  <td :class="this.ballypalTrainer ? 'star-column td orange' : 'star-column td'">
                    <img v-if="this.ballypalTrainer" src="~@/assets/star.png" />
                  </td>
                  <td class="column1 td">
                    {{ certs.find((item) => item.cert.includes('BallyPal')) ? 'BallyPal' : '' }}
                  </td>
                  <td class="td date">
                    {{ this.ballyPal || this.ballypalTrainer ? findCertDate('BallyPal') : '' }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td :class="pldTrainer ? 'star-column td orange' : 'star-column td'">
                    <img v-if="pldTrainer" src="~@/assets/star.png" alt="" />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Portable Loading Dock')) ||
                      this.pldTrainer
                        ? 'Portable Loading Dock'
                        : ''
                    }}
                    <!-- <img v-if="liftTrainer" src="~@/assets/star.png" /> -->
                  </td>
                  <td class="td date">
                    {{ this.pld || this.pldTrainer ? findCertDate('Portable Loading Dock') : '' }}
                  </td>
                </tr>
              </table>

              <div class="horizontal-bottom-bottom">
                RECERTIFICATION REQUIRED BEFORE EXPIRATION DATE
              </div>
            </div>
            <div class="horizontal-images">
              <div class="row">
                <div class="image">
                  <img
                    v-if="this.certs.find((item) => item.cert.includes('Fork Lift'))"
                    src="~@/assets/forklift.png"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    src="~@/assets/epj.png"
                    v-if="this.certs.find((item) => item.cert.includes('Electric Pallet Jack'))"
                    alt=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="image">
                  <img
                    src="~@/assets/OP.png"
                    v-if="this.certs.find((item) => item.cert.includes('Order Picker'))"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    v-if="this.certs.find((item) => item.cert.includes('Pacer'))"
                    src="~@/assets/pacer.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="image">
                  <img
                    src="~@/assets/reach.png"
                    v-if="this.certs.find((item) => item.cert.includes('Reach'))"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    v-if="
                      this.certs.find((item) => item.cert.includes('Electric Ladder')) ||
                      this.ladder.length ||
                      this.ladderTrainer
                    "
                    src="~@/assets/electric-ladder.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="image">
                  <img
                    v-if="this.certs.find((item) => item.cert.includes('BallyPal'))"
                    src="~@/assets/ballypal.png"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    v-if="
                      this.certs.find((item) => item.cert.includes('Portable Loading Dock')) ||
                      this.pldTrainer
                    "
                    src="~@/assets/pld.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="view === 'vertical'" class="vertical">
          <div class="horizontal-top">
            <img class="logo" src="../assets/new.png" alt="" />
            <div class="horizontal-top-right">
              <div class="horizontal-top-right-top">
                <div class="horizontal-top-right-top-left">
                  <div class="lift-equipment-license">LIFT EQUIPMENT LICENSE</div>
                  <div class="store">STORE {{ store }}</div>
                </div>
                <div class="horizontal-top-right-top-right"></div>
              </div>
              <div class="horizontal-top-right-bottom name">{{ name }}</div>
            </div>
          </div>
          <div v-if="this.ladderTrainer || this.liftTrainer" class="badge__info-vert">
            <div class="star"><img src="~@/assets/star.png" /></div>
            <strong>TRAINER</strong>
          </div>
          <div class="vertical-bottom">
            <div class="horizontal-bottom-left">
              <table class="table" :class="liftTrainer ? 'trainer-table' : ''">
                <tr class="principle-row tr">
                  <td class="title-column1 principle td">
                    <i v-if="this.principles[0]">{{
                      this.principles[0].cert | formatSingleCerts
                    }}</i>
                  </td>
                  <td class="principle td principle-date">
                    <i v-if="this.principles[0]">{{ this.principles[0].exp | filterDate }}</i>
                  </td>
                </tr>
              </table>

              <table class="table">
                <tr class="title-row tr">
                  <td class="title-column1 td">LIFT TRUCKS</td>
                  <td class="td date-title">EXPIRATION</td>
                </tr>
              </table>

              <table class="table">
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer & this.fl.length ? 'star-column td orange' : 'star-column td'
                    "
                  >
                    <img v-if="liftTrainer && this.fl.length" src="~@/assets/star.png" alt="" />
                  </td>
                  <td class="column1 td">
                    {{ certs.find((item) => item.cert.includes('Fork Lift')) ? 'Forklift' : '' }}
                  </td>
                  <td class="td date">
                    {{ findCertDate('Fork Lift') }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Electric Pallet Jack')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Electric Pallet Jack')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Electric Pallet Jack'))
                        ? 'Electric Pallet Jack'
                        : ''
                    }}
                  </td>
                  <td class="td date">
                    {{ findCertDate('Electric Pallet Jack') }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Order Picker')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Order Picker')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Order Picker')) ? 'Order Picker' : ''
                    }}
                  </td>
                  <td class="td date">
                    {{ findCertDate('Order Picker') }}
                  </td>
                </tr>

                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Pacer')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Pacer')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{ certs.find((item) => item.cert.includes('Pacer')) ? 'Pacer' : '' }}
                  </td>
                  <td class="td date">
                    {{ findCertDate('Pacer') }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td
                    :class="
                      liftTrainer && findCertDate('Reach Truck')
                        ? 'star-column td orange'
                        : 'star-column td'
                    "
                  >
                    <img
                      v-if="liftTrainer && findCertDate('Reach Truck')"
                      src="~@/assets/star.png"
                      alt=""
                    />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Reach Truck')) ? 'Reach Truck' : ''
                    }}
                  </td>
                  <td class="td date">
                    {{ findCertDate('Reach Truck') }}
                  </td>
                </tr>
              </table>

              <table class="table">
                <tr class="title-row tr">
                  <td class="title-column1 td">ADDITIONAL LIFT EQUIPMENT</td>
                  <td class="td date-title">EXPIRATION</td>
                </tr>
              </table>

              <table class="table extra">
                <tr class="tr">
                  <td :class="this.ladderTrainer ? 'star-column td orange' : 'star-column td'">
                    <img v-if="this.ladderTrainer" src="~@/assets/star.png" />
                  </td>
                  <td class="column1 td">
                    {{ this.ladder.length || this.ladderTrainer ? 'Electric Ladder ' : '' }}
                  </td>
                  <td class="td date">
                    {{ this.ladderTrainer || this.ladder.length ? 'NO EXP.' : '' }}
                  </td>
                </tr>
                <tr class="tr">
                  <td :class="this.ballypalTrainer ? 'star-column td orange' : 'star-column td'">
                    <img v-if="this.ballypalTrainer" src="~@/assets/star.png" />
                  </td>
                  <td class="column1 td">
                    {{ certs.find((item) => item.cert.includes('BallyPal')) ? 'BallyPal' : '' }}
                  </td>
                  <td class="td date">
                    {{ this.ballyPal || this.ballypalTrainer ? findCertDate('BallyPal') : '' }}
                  </td>
                </tr>
                <tr :class="liftTrainer ? 'trainer tr' : 'tr'">
                  <td :class="pldTrainer ? 'star-column td orange' : 'star-column td'">
                    <img v-if="pldTrainer" src="~@/assets/star.png" alt="" />
                  </td>
                  <td class="column1 td">
                    {{
                      certs.find((item) => item.cert.includes('Portable Loading Dock')) ||
                      this.pldTrainer
                        ? 'Portable Loading Dock'
                        : ''
                    }}
                  </td>
                  <td class="td date">
                    {{ this.pld || this.pldTrainer ? findCertDate('Portable Loading Dock') : '' }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="vertical-images">
              <div class="row">
                <div class="image">
                  <img
                    v-if="this.certs.find((item) => item.cert.includes('Fork Lift'))"
                    src="~@/assets/forklift.png"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    src="~@/assets/epj.png"
                    v-if="this.certs.find((item) => item.cert.includes('Electric Pallet Jack'))"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    src="~@/assets/OP.png"
                    v-if="this.certs.find((item) => item.cert.includes('Order Picker'))"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    v-if="this.certs.find((item) => item.cert.includes('Pacer'))"
                    src="~@/assets/pacer.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="image">
                  <img
                    src="~@/assets/reach.png"
                    v-if="this.certs.find((item) => item.cert.includes('Reach'))"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    v-if="
                      this.certs.find((item) => item.cert.includes('Electric Ladder')) ||
                      this.ladder.length ||
                      this.ladderTrainer
                    "
                    src="~@/assets/electric-ladder.png"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    v-if="this.certs.find((item) => item.cert.includes('BallyPal'))"
                    src="~@/assets/ballypal.png"
                    alt=""
                  />
                </div>
                <div class="image">
                  <img
                    v-if="
                      this.certs.find((item) => item.cert.includes('Portable Loading Dock')) ||
                      this.pldTrainer
                    "
                    src="~@/assets/pld.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="horizontal-bottom-bottom">
              RECERTIFICATION REQUIRED BEFORE EXPIRATION DATE
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'certs',
    'ladderTrainer',
    'liftTrainer',
    'ballypalTrainer',
    'pldTrainer',
    'name',
    'updated_name',
    'principles',
    'store',
  ],
  data() {
    return {
      equip: [
        'Reach Truck',
        'EPJ',
        'Order Picker',
        'Pacer',
        'Forklift',
        'Principles of Lift Truck Safety',
        'BallyPal',
        'Portable Loading Dock',
      ],
      full_name: '',
      ladder: [],
      ballyPal: [],
      pld: [],
      fl: [],
      view: 'horizontal',
    };
  },
  methods: {
    findCertDate(name) {
      const cert = this.certs.find((item) => item.cert.includes(name));
      if (cert && cert?.exp) return this.formatDate(cert?.exp);
      else return '';
    },
    randomNumber(index) {
      return parseInt(Math.random() * 1000000000000) + index;
    },
    isIncluded(el) {
      const gFilters = this.$options.filters;
      let filteredEl = gFilters.formatSingleCerts(el);

      return this.equip.includes(filteredEl);
    },
    createClass(el) {
      const gFilters = this.$options.filters;
      let c = gFilters.formatSingleCerts(el);
      c = c.split(' ').join('-').toLowerCase();
      return c;
    },
    formatDate(date) {
      const gFilters = this.$options.filters;
      return gFilters.filterDate(date);
    },
    filterCerts() {
      return this.certs.filter((cert) => {
        return (
          cert.cert != 'Electric Ladder' &&
          cert.cert != 'Electric Ladder Training' &&
          cert.cert != 'BallyPal' &&
          cert.cert != 'Store/MET Portable Loading Dock Certification' &&
          cert.cert != 'Store/MET BallyPal Powered Pallet Jack Certification'
        );
      });
    },
    hasPld() {
      let c = this.certs.filter((cert) => {
        return cert?.cert === 'Store/MET Portable Loading Dock Certification';
      });
      this.pld = c;
    },
    hasEL() {
      let c = this.certs.filter((cert) => {
        return cert?.cert.includes('Electric Ladder');
      });
      this.ladder = c;
    },
    hasFL() {
      let c = this.certs.filter((cert) => {
        return cert?.cert.includes('Fork Lift');
      });
      this.fl = c;
    },
    hasBallypal() {
      let c = this.certs.filter((cert) => {
        return cert?.cert === 'Store/MET BallyPal Powered Pallet Jack Certification';
      });
      this.ballyPal = c;
    },
  },
  // mounted() {
  //   this.hasPld();
  //   this.hasBallypal();
  // },

  beforeUpdate() {
    console.log('!!!!', this.certs);
    this.hasPld();
    this.hasBallypal();
    this.hasEL();
    this.hasFL();
  },
};
</script>

<style lang="scss">
.principle-row {
  stroke-width: 0.43px;
  stroke: #a6a6a6;
  background: #e0e0e0;
  font-family: Lato;
  font-size: 14px;
  border: solid black 1px;
}
.horizontal-images {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  margin-left: 7px;
  border: solid black 1px;
}
.horizontal-top {
  display: flex;
  margin-bottom: 5px;
}
.horizontal-bottom {
  display: flex;
}
.principle {
  height: 18px;
}
.horizontal-top-right-top {
  display: flex;
  border-bottom: solid #f76b1c 2px;
}
.vertical-images {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  border: solid black 1px;
}
.orange {
  background: #f76b1c;
  padding: 2px;
}
.horizontal-top-right-top-right {
  display: flex;
  margin-right: auto;
}
.star-column {
  width: 20px;
}
.column1 {
  width: 214px;
  height: 23px;
  text-align: left;
  text-transform: uppercase;
}
.extra {
  background: #e0e0e0;
}

.table,
.th,
.td {
  border: 1px darkgray solid;
  padding: 1px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bolder;
}
.table {
  border-collapse: collapse;
  margin-top: 4px;
}
.date {
  width: 120px;
  text-align: right;
  padding-right: 5px;
}
.image {
  border: solid black 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 71px;
  padding: 5px;
}
.title-row {
  background: black;
  color: white;
  font-size: 16px;
}
.title-column1 {
  width: 238px;
  text-align: left;
  font-size: 15px;
}
.date-title {
  font-size: 15px;
  width: 120px;
  padding-right: 5px;
  // text-align: right;
}
.principle-date {
  width: 120px;
  font-size: 15px;
  text-align: right;
  padding-right: 5px;
}
i {
  // margin-right: 5px;
}
.horizontal-top-right {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  border-bottom: solid #f76b1c 10px;
  width: 100%;
}
.row {
  display: flex;
}
.lift-equipment-license {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  width: 272px;
}
.selected {
  background: #f76b1c;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  cursor: pointer;
}
.horizontal-bottom-bottom {
  background: #f76b1c;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  padding: 11px;
  width: 346px;
  margin-top: 5px;
}
.badge__info-trainer {
  display: flex;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  color: #f76b1c;
  margin-left: 100px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.badge__info-vert {
  display: flex;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  color: #f76b1c;
  margin-right: 100px;
  width: 100%;
}
.logo {
  width: 75px;
  height: 75px;
}
.star {
  background: #f76b1c;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.unselected {
  background: #f0efef;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  cursor: pointer;
}
.right-top {
  width: 100%;
  display: flex;
}
.right {
  margin-left: 25px;
}
.name {
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
}
.orientation-text {
  margin-right: auto;
  display: flex;
  /* width: 100%; */
  justify-content: center;
  text-align: right;
  align-items: center;
}
.license-text {
  margin-left: auto;
  width: 100%;
  text-align: left;
}
.preview {
  background: #f0efef;
  display: flex;
  width: 601px;
  height: 536px;
  padding: 76px 85px 75px 86px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.headers {
  display: flex;
}
.horizontal {
  fill: #fff;
  stroke-width: 0.5px;
  stroke: #c4bcbb;
  width: 600px;
  height: 400px;
  background: white;
  padding: 30px;
}
.store {
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  color: #f76b1c;
}
.vertical {
  fill: #fff;
  stroke-width: 0.5px;
  stroke: #c4bcbb;
  height: 585px;
  width: 368px;
  background: white;
  padding: 30px;
}
.certification-details {
  display: flex;
  padding: 0 30px 40px;
  flex-template-columns: 434px min-content;
  font-size: 14px;
}

.certification-details__equipment {
  // display: flex;
  // flex-template-columns: 1fr 1fr 1fr;
  flex-template-columns: 40% 40% 20%;
  align-content: start;

  h4 {
    justify-self: left;
    height: 10px;
    font-size: 16px;
  }

  h4.equipment-name {
    margin-right: 85px;
  }

  h4.equipment-expiration {
    margin-right: 85px;
  }

  h4.equipment-trainer {
    flex-column-start: 3;
  }

  .electric-ladder,
  .forklift,
  .reach-truck,
  .principles,
  .equipment-container {
    display: flex;
    flex-column-start: 1;
    flex-column-end: 3;
    justify-items: left;
    border-bottom: dotted 1px #dbdada;

    .trainer {
      display: flex;
      justify-content: center;
    }
  }

  .principles {
    border-bottom: solid 1px #dbdada;
  }

  .principles strong {
    font-style: italic;
    max-width: 120px;
  }

  // .electric-ladder {
  //   border-bottom: solid 1px #dbdada;
  // }

  .last {
    border: none;
  }

  .dotted {
    border-bottom: dotted 1px #dbdada;
  }

  .equipment {
    display: flex;
    flex-column: 1;
    width: 163px;
    max-width: 163px;
    justify-self: start;
    text-align: left;
  }

  .expiration,
  .trainer {
    display: flex;
    flex-column: 2;
    width: 111px;
    max-width: 163px;
    text-align: left;
  }
}
.certification-details__license-preview {
  flex-column: 2;
  justify-self: left;
  text-align: left;

  h4 {
    font-size: 16px;
  }

  .preview__container {
    background-color: #f0efef;
    min-height: 300px;
    padding: 20px;
    width: auto;
  }

  .badge {
    background: #fff;
    height: 410px;
    width: 547px;
    flex-template-rows: auto 1fr auto;
    display: flex;

    &__header {
      align-content: center;
      background: #000;
      display: flex;
      height: 48px;

      i {
        font-weight: bold;
        font-style: normal;
        margin: 0 10px;
      }

      p {
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 44px;
        text-align: left;
        text-transform: uppercase;

        img:first-child {
          vertical-align: middle;
          margin-left: 21px;
          margin-right: 10px;
          height: 30px;
          width: 30px;
        }

        .badge__info-trainer {
          background: #f76b1c;
          font-size: 12px;
          font-weight: bold;
          line-height: 48px;
          justify-content: end;
          float: right;
          padding: 0 16px 0 29px;

          img:first-child {
            margin-left: 5px;
            margin-right: 5px;
            height: 14px;
            width: 14px;
            vertical-align: -2px;
          }
        }
      }
    }

    &__info-store {
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
    }

    &__info-bar {
      background: #ddd;
      color: #000;
      display: flex;
      height: 31px;
      font-size: 16px;
      font-weight: bold;
      padding: 0 35px;
      align-content: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;

        &:first-child {
          flex-column: 1;
        }

        &:last-child {
          flex-column: 2;
        }

        img {
          display: flex;
          flex-column: 2;
          margin-left: 5px;
        }
      }

      &.trainer {
        background: #f76b1c;
        color: #fff;
        justify-content: space-between;
      }
    }
    &__content {
      display: flex;
      height: 282px;
      flex-template-columns: 45% 55%;

      .column-1 {
        display: flex;
        flex-column: 1;
        padding: 15px;
        max-height: 265px;

        span {
          display: flex;
          height: 75px;
          width: 75px;

          &:nth-child(odd) {
            flex-column: 1;
          }
          &:nth-child(even) {
            flex-column: 2;
          }
        }
      }

      .column-2 {
        display: flex;
        flex-column: 2;
        padding: 15px;
        align-content: start;

        .name {
          font-size: 1.5vw;
          margin-bottom: 15px;
          margin-top: 0;
          text-transform: uppercase;
        }

        .trainer-table {
          border: none;
        }

        .ladder {
          margin-top: 18px;
        }

        table {
          border: 1px solid #ddd;
          border-collapse: collapse;

          td {
            border-bottom: 1px solid #ddd;
            font-size: 9px;
            font-weight: bold;
            text-transform: uppercase;
            padding: 6px 15px;

            &:nth-child(even) {
              font-weight: normal;
            }

            &.principle {
              background: #eaeaea;
              font-size: 7px;
              padding: 7px 15px;
            }
          }

          td:first-child {
            width: 62%;
          }

          tr:last-child {
            td {
              border: none;
            }
          }

          &.trainer,
          tr.trainer {
            background: #f76b1c;
            border-color: #f76b1c;
            color: #fff;
            align-content: center;

            td {
              border-color: #f76b1c;
            }

            img {
              display: inline-block;
              vertical-align: -1px;
              margin-left: 5px;
              height: 11px;
              width: 12px;
            }
          }
        }
      }
    }
    &__footer {
      align-content: center;
      background: #000;
      color: #fff;
      display: flex;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      height: 31px;

      &.trainer {
        background: #f76b1c;
      }
    }
  }
}
</style>
