<template>
  <div class="login">
    <Logo width="225px" height="179px" />
    <p>
      The Home Depot uses your network username and password to login to the
      Lift Equipment Reporting & License Tool. Continue to log in through your
      network.
    </p>
    <Button text="Continue" width="180" @click.native="ssoLogin" />
  </div>
</template>

<script>
import axios from "axios";
import Logo from "@/components/Logo.vue";
import Button from "@/components/Button.vue";

export default {
  name: "Login",
  components: {
    Button,
    Logo,
  },
  methods: {
    ssoLogin: () => {
      axios.post(process.env.VUE_APP_LOGIN_URL, {}).then((resp) => {
        window.location.href = resp.data.url;
      });
    },
  },
};
</script>

<style lang="scss">
.login {
  align-items: center;
  background: rgba(46, 17, 1, 0.7);
  border-radius: 50px;
  box-sizing: border-box;
  display: grid;
  height: 506px;
  justify-items: center;
  padding: 53px 52px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 355px;

  p {
    color: #fff;
    font-size: 16px;
    display: flex;
    line-height: 19px;
    margin: 42px 0;
    text-align: left;
    width: 257px;
  }
}
</style>
