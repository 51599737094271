<template>
  <div class="logo-logged-in">
    <img src="@/assets/logged-in-logo.svg" alt="HD Lift Equipment">
    <h2>Overview</h2>
    <p>Store {{ this.$route.params.store }}</p>
    <div v-if="showStoreChange" class="update-store">
      <input type="text" v-model="storeNumber" />
      <Button text="update" @click.native="setStore" />
    </div>
    <a v-if="!showStoreChange" href="#" @click="setStoreChange">Change</a>
  </div>
</template>

<script>
import Button from './Button.vue'
export default {
  components: { Button },
  watch: {
    '$route'() {
      this.storeNumber = ''
      this.showStoreChange = false
    },
    storeNumber(value) {
      let store = document.querySelector('.update-store input')

      if(value.length != 4) {
        store.classList.add('error')
      } else {
        store.classList.remove('error')
        this.valid = true
      }
    }
  },
  data() {
    return {
      showStoreChange: false,
      storeNumber: '',
      valid: false
    }
  },
  methods: {
    setStoreChange() {
      this.showStoreChange = true
    },
    setStore() {
      if(this.valid) {
        this.$emit('updateStore', this.storeNumber)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-logged-in {
  align-items: center;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  max-width: 255px;
  text-align: left;

  img {
    display: grid;
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 103px;
    height: 107px;
  }

  h2 {
    display: grid;
    grid-column: 2;
    grid-row: 1;
    font-size: 30px;
    margin: -50px 0 0;
  }

  p {
    display: grid;
    grid-column: 2;
    grid-row: 1;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 -26px;
  }

  a {
    display: grid !important;
    grid-column: 2;
    grid-row: 2 !important;
    font-size: 14px;
    color: #F76B1C;
    justify-self: left !important;
    margin: -26px 0 0;
  }

  .update-store {
    display: grid;
    grid-column: 2;
    margin-top: -20px;

    input {
      display: inline-block;
      margin-right: 10px;

      &:focus {
        outline: none;
      }

      &.error {
        background: #f8d7da;
        border: none;
        border: 1px solid #f7a2a6;
      }
    }
    button {
      display: inline-block;
      height: 30px;
      padding: 0 20px;
    }
  }
}
</style>