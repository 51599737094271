<template>
  <div class="overview">
    <LoadingModal v-if="showLoading" />
    <BatchModal v-if="showBatch" @selectView="(view) => getWithView(view)" />
    <SupportModal v-if="showSupport" @email-sent="hideSupport" />
    <div class="overview__header-container">
      <Header :email="email" />
    </div>
    <div class="content__header">
      <LogoLoggedIn :location="store" @updateStore="changeStore" />
      <!-- <Button
        class="support"
        text="Support"
        @click.native="handleShowSupport"
      /> -->
      <Button
        class="download-report"
        text="Download Report"
        @click.native="downloadReport()"
        image="/img/document-icon.svg"
      />
    </div>
    <div class="overview__filter-container">
      <div class="filter__header">
        <h3>Filter</h3>
        <div class="departments">
          <select v-model="department">
            <option value="">All Departments</option>
            <option
              v-for="(department, index) in departments"
              :key="index"
              :value="department.old_dept_name"
              >{{ department.dept_name }}</option
            >
          </select>
        </div>
      </div>
      <h4>Equipment</h4>
      <Filters @filter-certs="filterCerts" />
    </div>

    <div class="overview__table-container">
      <!-- <div class="button-area">
        <div
          :class="
            this.selectedItems.length ? 'batch-button' : 'batch-button disable'
          "
          @click="(showBatch = true), (type = 'print')"
        >
          BATCH PRINT <img src="../assets/print.png" alt="" />
        </div>
        Select licenses to batch print
      </div> -->
      <div class="current__certifications">
        <h3>Current Certifications</h3>
        <div class="sort-search">
          <label
            >Sort By
            <select v-model="sort">
              <!-- <option>Newest - Oldest </option>
              <option>Oldest - Newest </option> -->
              <option value="asc" selected>Alphabetical A - Z</option>
              <option value="desc">Alphabetical Z - A</option>
            </select>
          </label>

          <input type="text" placeholder="Search" v-model="search" />
        </div>
      </div>

      <OverviewTable
        @selectBatch="
          (items) => {
            selectedItems = items;
          }
        "
        :certifications="filteredCertifications"
        :location="this.$route.query.location"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import BatchModal from "@/components/PrintModal.vue";
import LogoLoggedIn from "@/components/LogoLoggedIn.vue";
import Button from "@/components/Button.vue";
import OverviewTable from "@/components/OverviewTable.vue";
import Filters from "@/components/Filters.vue";
import CertificationService from "@/services/certification.service";
import ReportService from "@/services/report.service";
import SupportModal from "@/components/SupportModal.vue";
import LicenseService from "@/services/license.service.js";
import LoadingModal from "@/components/LoadingModal.vue";

export default {
  components: {
    Header,
    Button,
    LogoLoggedIn,
    OverviewTable,
    Filters,
    SupportModal,
    BatchModal,
    LoadingModal,
  },
  computed: {
    filteredCertifications() {
      const gFilters = this.$options.filters;
      let filters = this.filters.filter((el) => el !== "");
      let filtered = this.certifications;


      if (filters.length) {
        filtered = this.certifications.filter((certification) => {
          let formattedCerts = gFilters
            .formatCerts(certification.certs)
            .split(",");
          let formattedTrainer = gFilters
            .trainer(certification.certs)
            .split("/");
          formattedTrainer =
            formattedTrainer[0] == "EL/LT"
              ? formattedTrainer[0].split("/")
              : formattedTrainer;

          let formatted = formattedCerts
            .map((cert) => cert.trim())
            .concat(formattedTrainer);

          formatted.some((r) => {
            filters.includes(r);
          });

          return formatted.some((r) => filters.includes(r));
        });
      }

      if (this.department) {
        filtered = filtered.filter((cert) => {
          return cert.dept_name == this.department;
        });
      }

      if (this.search) {
        filtered = filtered.filter((cert) => {
          return (
            cert.first_name.includes(this.search.toUpperCase()) ||
            cert.last_name.includes(this.search.toUpperCase()) ||
            cert.associate_id.includes(this.search)
          );
        });
      }

      if (this.sort == "asc") {
        filtered = filtered.sort((a, b) =>
          a.last_name.localeCompare(b.last_name)
        );
      }

      if (this.sort == "desc") {
        filtered = filtered.sort((a, b) =>
          b.last_name.localeCompare(a.last_name)
        );
      }

      return filtered;
    },
  },
  data() {
    return {
      certifications: [],
      filters: [],
      departments: [],
      department: "",
      search: "",
      sort: "asc",
      email: "",
      store: "",
      showSupport: false,
      selected: "",
      selectedItems: [],
      showBatch: false,
      type: "",
      showLoading: false,
    };
  },
  methods: {
    async getWithView(view) {
      this.showBatch = false;
      if (this.type === "download") {
        this.downloadReport(view);
      }
      if (this.type === "print") {
        this.batchDownload(view);
      }
    },

    handleShowSupport() {
      this.showSupport = true;
    },
    hideSupport() {
      this.showSupport = false;
    },
    changeStore(store) {
      this.$router.replace({ name: "Overview", params: { store: store } });
      this.getCertifications();
    },
    downloadReport() {
      let location = this.$route.params.store;

      ReportService.getReportByStore(location)
        .then((res) => {
          const data = new Blob([res.data]);

          const defaultFilename = `Report_${location}.pdf`;
          if (typeof window.navigator.msSaveBlob === "function") {
            // If it is IE that support download blob directly.
            window.navigator.msSaveBlob(data, defaultFilename);
          } else {
            var blob = data;
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = defaultFilename;

            document.body.appendChild(link);

            link.click(); // create an <a> element and simulate the click operation.
          }
        })
        .catch((err) => console.log(err));
    },
    batchDownload(view) {
      this.showLoading = true;
      // let location = this.$route.params.store;
      LicenseService.getBatchLicences(this.selectedItems, view)
        // const data = new Blob([res.data], {
        //   type: "application/pdf",
        // });
        .then((res) => {
          const data = new Blob([res.data], {
            type: "application/pdf",
          });
          const defaultFilename = `name.pdf`;
          if (typeof window.navigator.msSaveBlob === "function") {
            // If it is IE that support download blob directly.
            window.navigator.msSaveBlob(data, defaultFilename);
          } else {
            var blob = data;
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = defaultFilename;

            document.body.appendChild(link);

            link.click(); // create an <a> element and simulate the click operation.
          }
          // const iframe = document.createElement("iframe");
          // iframe.src = fileUrl;
          // iframe.width = "100%";
          // iframe.height = "100%";
          // iframe.style.border = "none";

          // create an <a> element and simulate the click operation.
          // newWindow.document.body.appendChild(data);
          // newWindow.document.title = "My Custom Title"; // alternatively 'filename.pdf'
        })
        .finally(() => {
          setTimeout(() => {
            this.showLoading = false;
          }, 1500);
        })

        .catch((err) => console.log(err));
    },
    filterCerts(values) {
      this.filters = values;
    },
    getCertifications() {
      let location = this.$route.params.store;
      CertificationService.getByStore(location)
        .then((res) => {
          this.certifications = res.data;
        })
        .catch((err) => console.log(err));
    },
    getDepartments() {
      const gFilters = this.$options.filters;

      CertificationService.getDepartments()
        .then((res) => {
          this.departments = gFilters.formatDepartments(res.data);
        })
        .catch((err) => console.log(err));
    },
  },
  beforeMount() {
    const store = this.$route.params.store;
    this.store = store;

    const token = this.$route.query.token;
    const email = this.$route.query.email;
    const title = this.$route.query.title;
    const location = this.$route.query.location;

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("email", email);
      localStorage.setItem("title", title);
      localStorage.setItem("location", location);
    }

    if (!localStorage.getItem("token")) {
      this.$router.push({ name: "Login" });
    }

    this.getCertifications();
    this.getDepartments();
  },
  mounted() {
    this.email = localStorage.getItem("email");
  },
};
</script>

<style lang="scss">
.button-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.batch-button {
  cursor: pointer;
  display: inline-flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: linear-gradient(180deg, #fa9417 0%, #f76b1c 100%);
  width: 150px;
  height: 25px;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  // margin-bottom: 50px;
  margin-bottom: 10px;
}
.disable {
  pointer-events: none;
  background: gray;
}
.overview {
  background: #fff;
  display: grid;
  grid-template-areas:
    "header  header  header"
    "content-header content-header content-header"
    "name name name"
    "sidebar content content";
  width: 100%;
  max-width: 1250px;
  padding-bottom: 35px;
  margin: 50px auto;

  .overview__header-container {
    grid-area: header;
  }

  .content__header {
    border-bottom: 2px solid #ddd;
    grid-area: content-header;
    display: grid;
    padding: 25px 35px;
    grid-template-columns: 50% 25% 25%;
    align-items: center;
    margin-bottom: 20px;

    button,
    a {
      display: grid;
      grid-column-start: 3;
      grid-row: 1;
      justify-self: right;
      text-decoration: none;
    }

    a {
      grid-column-start: 2;
    }

    .support {
      grid-column-start: 2;
    }
  }

  .overview__table-container {
    align-content: start;
    display: grid;
    grid-area: content;
    grid-column-start: 2;
  }

  .overview__filter-container {
    align-content: start;
    display: grid;
    grid-area: sidebar;
    padding: 0 0 0 2em;

    .filter__header {
      text-align: left;
      font-weight: bold;
    }

    h4 {
      text-align: left;
    }

    .departments {
      text-align: left;

      select {
        padding: 5px 10px;
        border-radius: 5px;
        border: solid 1px #c3c3c3;
        margin: 0;
        font-family: "Lato", Arial, Helvetica, sans-serif;
        max-width: 165px;
      }
    }
  }

  .current__certifications {
    text-align: left;
    display: grid;
    align-items: center;

    h3 {
      color: #f76b1c;
      font-size: 24px;
      grid-column: 1;
    }

    .sort-search {
      grid-column: 2;

      label {
        font-weight: bold;
        font-size: 14px;
      }

      select,
      input {
        padding: 5px 10px;
        border-radius: 5px;
        border: solid 1px #c3c3c3;
        margin: 0 10px;
        font-family: "Lato", Arial, Helvetica, sans-serif;
      }
    }
  }
}
</style>
