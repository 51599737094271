<template>
  <div class="overlay">
    <div class="modal">
      <span class="close" @click="closeModal">Close X</span>
      <h2>Support</h2>
      <!-- <p>
        <strong>
          Please click
          <a
            href="https://portal.homedepot.com/sites/workdayknowledgedepot/Leader HR Resources Documents/Forms/AllItems.aspx?id=%2Fsites%2Fworkdayknowledgedepot%2FLeader%20%20HR%20Resources%20%20Documents%2FStore%2DMET%20Lift%20Equipment%20Safety%20Program%20%2D%20Job%20Aid%2Epdf&parent=%2Fsites%2Fworkdayknowledgedepot%2FLeader%20%20HR%20Resources%20%20Documents"
            target="_blank"
            >HERE</a
          >
          for information on how to administer the Store/MET Lift Equipment
          Program.
        </strong>
      </p> -->
      <p>Leave a message to contact Knowledge Depot</p>
      <p class="error" v-if="error">{{ this.error }}</p>

      <form>
        <label for="email"
          >Email Address
          <input id="email" v-model="email" />
        </label>
        <label for="message"
          >Message
          <textarea id="message" v-model="message"></textarea>
        </label>
        <Button text="Submit" @click.native="sendEmail" />
      </form>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import EmailService from "@/services/email.service";

export default {
  name: "SupportModal",
  components: {
    Button,
  },
  data() {
    return {
      email: "",
      message: "",
      error: "",
    };
  },
  methods: {
    closeModal() {
      this.error = "";
      this.email = "";
      this.message = "";
      this.$emit("email-sent");
    },
    sendEmail(e) {
      e.preventDefault();

      if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.email) && this.message.length > 1) {
        EmailService.sendEmail(this.email, this.message, name)
          .then(() => {
            this.error = "";
            this.email = "";
            this.message = "";
            this.$emit("email-sent");
          })
          .catch(() => {
            this.error = "There was an error sending your message";
          });
      } else {
        this.error =
          "Please make sure your email is correct and that you've entered a message";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  background: #fff;
  box-sizing: border-box;
  width: 462px;
  min-height: 535px;
  text-align: left;
  padding: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .error,
  a {
    color: red;
  }
  form {
    margin-top: 10px;
  }

  h2 {
    color: #f76b1c;
    margin-top: 0;
    font-size: 30px;
  }

  p {
    font-size: 16px;
    margin-bottom: 25px;
    line-height: 1.4;
  }

  label {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 25px;
  }

  input,
  textarea {
    display: block;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
    outline: none !important;
    padding: 5px 12px;

    &:focus {
      border: 1px solid #999;
    }
  }

  input {
    box-sizing: border-box;
    height: 33px;
  }

  textarea {
    box-sizing: border-box;
    height: 135px;
  }
}
</style>
