import Axios from 'axios'

const STORE_RESOURCE = process.env.VUE_APP_API_ENDPOINT + '/api/store-certifications'
const USER_RESOURCE = process.env.VUE_APP_API_ENDPOINT + '/api/user-certifications'
const DEPARTMENT_RESOURCE = process.env.VUE_APP_API_ENDPOINT + '/api/departments'

export default {
  getByStore(store) {
    return Axios.get(`${STORE_RESOURCE}/${store}`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
  },
  getByCertId(certId) {
    return Axios.get(`${USER_RESOURCE}/${certId}`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
  },
  getDepartments() {
    return Axios.get(`${DEPARTMENT_RESOURCE}`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
  }
};