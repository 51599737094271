import Axios from "axios";

const LICENSE_RESOURCE =
  process.env.VUE_APP_API_ENDPOINT + `/api/print-license`;
const BATCH_RESOURCE =
  process.env.VUE_APP_API_ENDPOINT + `/api/batch-print-license`

export default {
  getLicenseByCertID(certID, name, view) {
    if(name){
    return Axios.get(
      `${LICENSE_RESOURCE}?certID=${certID}&name=${name}&orientation=${view}`,
      { responseType: "blob" }
    );
    }else{
      return Axios.get(
        `${LICENSE_RESOURCE}?certID=${certID}&orientation=${view}`,
        { responseType: "blob" }
      );
    }
  },
  getBatchLicences(certIDs, view) {
    return Axios.post(
      `${BATCH_RESOURCE}`,
      { selected: certIDs, orientation: view },
      { responseType: "blob" }
    );
  },
};

